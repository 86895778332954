@import "./theme.sass"

body
  min-height: 100vh
  min-width: 100vw
  padding: 0
  margin: 0
  font-family: 'Roboto', sans-serif

#root
  min-height: 100%
  min-width: 100%
  padding: 0
  margin: 0

//* Fonts
@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

//* Colors
$primary: hsl(30, 100%, 56%, 1)
$secondary: hsl(203, 78%, 60%, 1)

$primary-100: hsl(30, 66%, 99%, 1)
$primary-200: hsl(30, 70%, 93%, 1)
$primary-300: hsl(30, 81%, 81%, 1)
$primary-400: hsl(30, 91%, 70%, 1)
$primary-500: hsl(30, 100%, 56%, 1)
$primary-600: hsl(30, 100%, 46%, 1)
$primary-700: hsl(30, 100%, 38%, 1)
$primary-800: hsl(30, 100%, 30%, 1)
$primary-900: hsl(30, 100%, 20%, 1)
$primary-1000: hsl(30, 100%, 12%, 1)
$primary-1100: hsl(30, 100%, 6%, 1)

$secondary-100: hsl(203, 78%, 99%, 1)
$secondary-200: hsl(203, 78%, 99%, 1)
$secondary-300: hsl(203, 78%, 99%, 1)
$secondary-400: hsl(203, 78%, 99%, 1)
$secondary-500: hsl(203, 78%, 99%, 1)
$secondary-600: hsl(203, 78%, 99%, 1)
$secondary-700: hsl(203, 78%, 99%, 1)
$secondary-800: hsl(203, 78%, 99%, 1)
$secondary-900: hsl(203, 78%, 99%, 1)
$secondary-1000: hsl(203, 78%, 99%, 1)
$secondary-1100: hsl(203, 78%, 99%, 1)
$secondary-1200: hsl(203, 78%, 99%, 1)
$secondary-1300: hsl(203, 78%, 99%, 1)

//* Border Radius
$border-radius: 8px
.cursor-pointer
  cursor: pointer

.styled-input
  gap: 16px
  label
    font-weight: bold
    font-family: 'Roboto', sans-serif
    color: #002C46
  .input-holder
    display: flex
    flex-direction: row
    position: relative
    justify-content: flex-start
    align-items: center
    width: 400px
    height: 40px
    border: 2px solid white
    border-radius: 8px
    background: rgba(white, 0.75)
  .on-focus
    border: 2px solid #002C46
  input
    width: 100%
    padding-left: 40px
    background: none
    outline: none
    color: #002C46
    border: 0

.review-cards-holder
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-items: flex-start
  border: 1px solid rgba(0,0,0,0.1)
  border-radius: 8px
  margin: 8px 0px
.review-card
  width: 300px
  border: 1px solid rgba(0,0,0,0.1)
  border-radius: 8px
  padding: 8px
  margin: 8px
  &:hover
    box-shadow: 0 0 8px rgba(0,0,0,0.1)
    background: rgba(0,0,0,0.03)
  .review-card-header
    display: flex
    align-items: center
    justify-content: space-between
    >div
      display: flex
      align-items: center
      justify-content: start
      gap: 8px
      >p
        font-size: 1rem
        font-weight: bold
        margin-top: 12px

@use '../../style/_theme' as theme
  
.styled-button
  width: 400px
  height: 48px
  background: theme.$primary
  color: theme.$primary-1100
  border: none
  border-radius: theme.$border-radius
  font-size: 24px
  font-family: 'Titan One', cursive
  &:hover
    background: darken(theme.$primary, 5%)
  &:active
    background: darken(theme.$primary, 10%)
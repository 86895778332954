@use '../../style/_theme' as theme
.loadingHolder
  position: absolute
  min-width: 100vw
  min-height: 100vh
  z-index: 9999
  background: rgba(255,255,255,0.5)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  backdrop-filter: blur(8px)
  .circle
    animation: spin 1.7s linear infinite
    color: theme.$primary
  p
    font-size: 1.5rem
    font-weight: 600
    margin-top: 1rem
    color: theme.$primary-1100
    font-family: 'Roboto', sans-serif
    margin-top: 1rem
    transition: all 0.3s ease-in-out

.localLoadingHolder
  width: 100%
  height: 100px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: absolute
  .circle
    animation: spin 1.7s linear infinite
    color: theme.$primary

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
